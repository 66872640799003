<template>
    <my-component></my-component>
</template>
<script>
import MyComponent from './Index.vue'
export default {
  components: {
    MyComponent
  }
}
</script>